import React from "react";
import { ReactComponent as Logo } from "../../../Assets/Images/ValorLogo.svg";
import { Button, Grid, Typography } from "@mui/material";
import styles from "../../../Assets/Styles/jss/index";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Box } from "@mui/system";

const index = () => {
  const paths = window.location.href.split("/");
  const storeslash = paths[paths.length - 2];
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Box className={classes.mainBox}>
      <Grid
        container
        alignItems="center"
        display="flex"
        justifyContent="space-between"
      >
        <Logo width="18%" height="18%" />
        <Grid marginLeft="auto" gap="1.5rem" display="flex">
          <Button
            variant="contained"
            className={classes.mainBtn}
            style={{ minWidth: "160px" }}
            onClick={() => navigate("/" + storeslash + "/change-settings")}
          >
            Change Settings
          </Button>
          <Button
            variant="contained"
            className={classes.mainBtn}
            style={{ minWidth: "160px" }}
            onClick={() => navigate("/" + storeslash + "/refund-order")}
          >
            Refund Order
          </Button>
          {/* <Button
            variant="contained"
            className={classes.mainBtn}
            style={{ minWidth: "160px" }}
            onClick={() => navigate("/" + storeslash + "/refunded-orders")}
          >
            Refunded Orders
          </Button> */}
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        container
        item
        margin="3rem 0 2rem 0"
        gap="2rem"
        flexDirection="column"
      >
        <Typography className={classes.heading}>
          Welcome to ValorPay payment gateway application for Shopify
          merchants.
        </Typography>
        <Typography className={classes.heading}>
          <span style={{ color: "#800000" }}>Change Settings - </span>Update
          configuration settings of the application and the keys that link you
          to your ValorPay account.
        </Typography>
        <Typography className={classes.heading}>
          <span style={{ color: "#800000" }}>Refund Order - </span>
          Orders that are marked 'Refunded' on the Shopify panel will be
          listed here. You can use ValorPay to process the refund payments in a
          fast and secure way.
        </Typography>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (app) => ({
  data: app.rootReducer
});

export default connect(mapStateToProps, {})(index);
