import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as Logo } from "../../../Assets/Images/ValorLogo.svg";
import styles from "../../../Assets/Styles/jss/index";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Index = (props) => {
  const paths = window.location.href.split("/");
  const storeslash = paths[paths.length - 2];
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      padding="2rem"
    >
      <Logo width="18%" height="18%" />
      <Typography margin="2rem 0">Getting Started</Typography>
      <Button
        variant="contained"
        className={classes.mainBtn}
        style={{ minWidth: "160px" }}
        onClick={() =>
          window.open("https://valorpaytech.com/contact-us/", "_blank")
        }
      >
        Create New Merchant
      </Button>
      <Button
        variant="contained"
        className={classes.mainBtn}
        style={{ minWidth: "160px", margin: "1rem 0" }}
        onClick={() => navigate("/" + storeslash + "/link-existing-account")}
      >
        Link Existing Account
      </Button>
    </Grid>
  );
};

Index.propTypes = {};

const mapStateToProps = (app) => ({
  data: app.rootReducer
});

export default connect(mapStateToProps, {})(Index);
