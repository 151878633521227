import * as types from "../Actions/ActionTypes";
import initialState from "./InitialState";

const user = (state = initialState.app.merchant, action) => {
  switch (action.type) {
    case types.UPDATE_MERCHANT_SETTINGS_API_CALL:
      return { ...state, updateSettings: false, error: false, loading: true };
    case types.UPDATE_MERCHANT_SETTINGS_API_CALL_SUCCESS:
      return {
        ...state,
        updateSettings: action.data,
        error: false,
        loading: false
      };
    case types.UPDATE_MERCHANT_SETTINGS_API_CALL_ERROR:
      return { ...state, updateSettings: false, error: true, loading: false };

    case types.GET_SETTINGS_API_CALL:
      return { ...state, settingsData: false, error: false, loading: true };
    case types.GET_SETTINGS_API_CALL_SUCCESS:
      return {
        ...state,
        settingsData: action.data,
        error: false,
        loading: false
      };
    case types.GET_SETTINGS_API_CALL_ERROR:
      return { ...state, settingsData: false, error: true, loading: false };

    default:
      return state;
  }
};

export default user;
