import React from "react";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate
} from "react-router-dom";
import Checkout from "../Pages/Checkout/Checkout";
import Refund from "../Pages/Refund/Refund";
import ExistingMerchant from "../Pages/ExistingMerchant/ExistingMerchant";
import MainPage from "../Pages/MainPage/Index";
import WelcomeScreen from "../Pages/WelcomeScreen/index";
import { connect } from "react-redux";
import AppLoader from "../../Components/Loader";
import RootLayout from "./RootLayout";
import OrderDetails from "../Pages/Refund/OrderDetails";
import { getQueryParams } from "../../Utils/commonFunctions";
const id=getQueryParams("id");
const page=getQueryParams("page");
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Navigate to={`/${id}/${page}`} replace />} />
      <Route path={`/${id}/setup`} element={<MainPage />} />
      <Route path="/:id/checkout" element={<Checkout />} />
      <Route path="/:id/change-settings" element={<ExistingMerchant />} />
      <Route path="/:id/link-existing-account" element={<ExistingMerchant />} />
      <Route path="/:id/refund-order" element={<Refund />} />
      <Route path="/:id/refunded-orders" element={<Refund />} />
      <Route
        path="/:id/refund-order/order-detail/"
        element={<OrderDetails />}
      />
      <Route path={`/${id}/dashboard`} element={<WelcomeScreen />} />
    </Route>
  )
);

const AppView = (props) => {
  return (
    <>
      <AppLoader
        loader={props.data.user.loading || props.data.payment.loading}
      />
      <RouterProvider router={router} />
    </>
  );
};

AppView.propTypes = {};

const mapStateToProps = (app) => ({
  data: app.rootReducer
});

export default connect(mapStateToProps, {})(AppView);
