import { configureStore } from "@reduxjs/toolkit";
import throttle from "lodash/throttle";
import { setupListeners } from "@reduxjs/toolkit/query";
import rootReducer from "./Reducers/Index";

export const store = configureStore({
  reducer: { rootReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat()
});

store.subscribe(throttle(() => store.getState(), 1000));
setupListeners(store.dispatch);
