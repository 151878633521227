import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { connect } from "react-redux";
import { Button, Collapse, Grid, TextField, Typography } from "@mui/material";
import styles from "../../../Assets/Styles/jss/index";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import {
  refundOrder,
  updateSearchDate,
  updatePagination
} from "../../../Redux/Actions/Operations";
import moment from "moment";
import { isEmpty } from "lodash";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import FilterListIcon from "@mui/icons-material/FilterList";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#093f7a !important",
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

const FormTheme = createTheme({
  components: {
    MuiStack: {
      styleOverrides: {
        root: {
          ".MuiTextField-root": {
            minWidth: "unset !important"
          }
        }
      }
    }
  }
});

const headCells = [
  {
    id: "created",
    disablePadding: true,
    label: "Refund Date",
    align: "left"
  },
  {
    id: "order_date",
    disablePadding: true,
    label: "Order Date",
    align: "left"
  },
  {
    id: "order_id",
    disablePadding: false,
    label: "Order ID",
    align: "center"
  },
  {
    id: "customer",
    disablePadding: false,
    label: "Customer",
    align: "center"
  },
  {
    id: "paymentstatus",
    disablePadding: false,
    label: "Payment Status",
    align: "center"
  },
  {
    id: "total_amount",
    disablePadding: false,
    label: "Refund Cost",
    align: "center"
  },
  {
    id: "action",
    disablePadding: false,
    label: "Action",
    align: "center"
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, numSelected, onSelectAllClick } =
    props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "normal" : "none"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string
};

const Refund = (props) => {
  const paths = window.location.href.split("/");
  const storeslash = paths[paths.length - 2];
  const useStyles = makeStyles(styles);
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [rows, setRows] = React.useState(props.data.payment.refundOrder);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(props.data.payment.pageDetails.page);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    props.data.payment.pageDetails.limit
  );
  const [searchValues, setSearchValues] = React.useState({
    ...props.data.payment.searchDetails
  });
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  React.useEffect(() => {
    const handleBackButton = (e) => {
      navigate(`/${storeslash}/dashboard`);
    };
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  const handleClickAway = () => {
    setOpen(false);
  };

  const REFUNDED_ORDER_FLAG = location.pathname.includes("refunded-orders")
    ? true
    : false;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    props.updatePagination({
      page: newPage,
      limit: rowsPerPage
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    props.updatePagination({
      page: 0,
      limit: parseInt(event.target.value, 10)
    });
    setPage(0);
  };

  const handleDetail = (id, order_id) => {
    navigate(
      `/${storeslash}/refund-order/order-detail?refundId=${id}&orderId=${order_id}`
    );
  };

  React.useEffect(() => {
    let data = {
      page: page + 1,
      limit: rowsPerPage,
      storeslash: storeslash,
      from: `${moment(searchValues.from).format("YYYY-MM-DD")}T00:00:00-00:00`,
      to: `${moment(searchValues.to).format("YYYY-MM-DD")}T23:59:59-00:00`
    };
    props.refundOrder(data);
  }, [rowsPerPage, page]);

  React.useEffect(() => {
    if (!props.data.payment.loading) {
      setRows(props.data.payment.refundOrder);
    }
  }, [props]);

  const onChange = (value, type) => {
    const allState = { ...searchValues };
    allState[type] = value;
    setSearchValues({ ...allState });
    props.updateSearchDate(allState);
  };

  const getRefundOrderData = () => {
    const data = {
      page: 1,
      limit: 10,
      storeslash: storeslash,
      from: `${moment(searchValues.from).format("YYYY-MM-DD")}T00:00:00-00:00`,
      to: `${moment(searchValues.to).format("YYYY-MM-DD")}T23:59:59-00:00`
    };
    props.updatePagination({ page: 0, limit: 10 });
    props.refundOrder(data);
    handleClickAway();
  };

  const SearchFilter = () => {
    const onClear = () => {
      handleClickAway();
      props.updateSearchDate({
        from: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
        to: new Date()
      });
      props.updatePagination({
        page: 0,
        limit: 10
      });
      setPage(0);
      setRowsPerPage(10);
      setSearchValues({
        from: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
        to: new Date()
      });
    };

    return (
      <ThemeProvider theme={FormTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DemoContainer components={["DatePicker", "DatePicker"]}>
            <DatePicker
              fullWidth
              id="from"
              name="from"
              label="Start Date"
              views={["year", "month", "day"]}
              onChange={(value) => onChange(value, "from")}
              value={searchValues.from}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              fullWidth
              id="to"
              name="to"
              label="End Date"
              views={["year", "month", "day"]}
              minDate={searchValues.from}
              onChange={(value) => onChange(value, "to")}
              value={searchValues.to}
              renderInput={(params) => <TextField {...params} />}
            />
          </DemoContainer>
          <Grid
            container
            style={{ justifyContent: "end" }}
            marginTop="1.5rem"
            gap="1.5rem"
          >
            <Button
              variant="contained"
              onClick={() => onClear()}
              className={classes.backBtn}
              style={{ maxWidth: "100px" }}
            >
              clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => getRefundOrderData()}
              className={classes.mainBtn}
              style={{ maxWidth: "100px" }}
            >
              Search
            </Button>
          </Grid>
        </LocalizationProvider>
      </ThemeProvider>
    );
  };
  return (
    <Box className={classes.mainBox}>
      <Grid container alignItems="center" className={classes.gridBox}>
        <Typography className={classes.mainHeading}>Refund Orders</Typography>
        <Grid
          marginLeft="auto"
          gap="2rem"
          display="flex"
          className={classes.buttonBox}
        >
          <Button
            className={classes.backBtn}
            style={{ minWidth: "160px" }}
            onClick={() => {
              navigate(`/${storeslash}/dashboard`);
            }}
            variant="contained"
          >
            Back
          </Button>
        </Grid>
      </Grid>
      {rows && rows.data && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "2rem"
            }}
          >
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={handleClickAway}
            >
              <Box style={{ width: "35%" }}>
                <div
                  className={classes.subHeading}
                  style={{ display: "flex", gap: "8px", alignItems: "center" }}
                >
                  <FilterListIcon
                    onClick={() => handleClick()}
                    style={{ cursor: "pointer" }}
                  />
                  Filter
                </div>
                <Collapse
                  in={open}
                  timeout="auto"
                  unmountOnExit
                  className={classes.overLayBox}
                >
                  <SearchFilter />
                </Collapse>
              </Box>
            </ClickAwayListener>
            <TablePagination
              rowsPerPageOptions={[
                5,
                10,
                25,
                {
                  label: "All",
                  value: rows.meta.pagination.total
                }
              ]}
              component="div"
              count={rows.meta.pagination.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          <span style={{ fontSize: "12px" }}>
            <strong>Note: </strong>List shows all refunds initiated in the last
            7 days.
          </span>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {!isEmpty(rows?.data) ? (
                  rows?.data.map((row) => (
                    <StyledTableRow key={row.id}>
                      <TableCell align="left">
                        {moment(row.created).format("ddd, D MMM YYYY")}
                      </TableCell>
                      <TableCell align="left">
                        {moment(row.order_date).format("ddd, D MMM YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.order_number}</TableCell>
                      <TableCell align="center">{row.customer}</TableCell>
                      <TableCell align="center">{row.paymentstatus}</TableCell>
                      <TableCell align="center">
                        {row.currency}
                        {row.total_amount}
                      </TableCell>
                      <TableCell
                        onClick={() => handleDetail(row.id, row.order_id)}
                        align="center"
                        style={{ cursor: "pointer" }}
                      >
                        <span style={{ color: "#C70039", fontWeight: "bold" }}>
                          {row.refundAction == 1 ? "Refund" : "View"}
                        </span>
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableCell align="center" colSpan={12}>
                    No refunds initiated in the past 7 days.
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[
              5,
              10,
              25,
              {
                label: "All",
                value: rows.meta.pagination.total
              }
            ]}
            component="div"
            count={rows.meta.pagination.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Box>
  );
};

Refund.propTypes = {
  refundOrder: PropTypes.func,
  updateSearchDate: PropTypes.func,
  updatePagination: PropTypes.func
};

const mapStateToProps = (app) => ({
  data: app.rootReducer
});

export default connect(mapStateToProps, {
  refundOrder,
  updateSearchDate,
  updatePagination
})(Refund);
