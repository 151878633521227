/* eslint-disable no-param-reassign */
import { combineReducers } from "redux";
import payment from "./PaymentReducer";
import user from "./UserReducer";
import loader from "./loaderReducer";

const appReducer = combineReducers({
  payment,
  user,
  loader
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
